import { Button } from 'components/Button'
import { Input } from 'components/Input/Input'
import { Title } from 'components/Title'
import { LoginState } from 'contexts/LoginProvider'
import { useLoginContext } from 'hooks/useLoginContext'
import { ChangeEvent, ClipboardEventHandler, KeyboardEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HiLockClosed } from 'react-icons/hi'

export function LoginCode() {
  const { t } = useTranslation()
  const { status, isLoading } = useLoginContext()

  useEffect(() => {
    document.getElementById('token1')?.focus()
  }, [])

  const handleTokenDown = (e: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    const cur = parseInt(e.currentTarget.id.replace('token', ''))

    if ((e as KeyboardEvent<HTMLInputElement>).key === 'Backspace') {
      if (cur > 0 && e.currentTarget.value === '') {
        document.getElementById('token' + (cur - 1))?.focus()
      }
      return
    }

    if (!/[0-9]/.test(e.currentTarget.value)) {
      e.currentTarget.value = ''
      return
    }
    if (cur >= 6) {
      return
    }

    document.getElementById('token' + (cur + 1))?.focus()
  }

  const handlePaste: ClipboardEventHandler<HTMLInputElement> = e => {
    let paste = e.clipboardData.getData('text')
    if (paste.length !== 6 || !/[0-9]{6}/.test(paste)) {
      return
    }

    paste.split('').forEach((num, i, a) => {
      const id = `token${i + 1}`
      const input = document.getElementById(id) as HTMLInputElement
      input.value = num
      if (i === a.length - 1) {
        input.focus()
      }
    })
  }

  return (
    <div>
      <div className="flex flex-col items-center justify-center">
        <Title center type="h1" weight="bold" size="2xl" className="mb-6" ariaLabel="sign in message">
          Enter your login code
        </Title>
      </div>
      <label htmlFor={'token1'} className="sr-only block text-sm font-medium text-gray-500">
        {t('token')}
      </label>
      <div className="my-1 text-center">
        <div className="inline-flex items-center justify-center space-x-2">
          <div className="w-12">
            <Input
              id="token1"
              name="token1"
              className="py-3 text-center"
              minLength={1}
              maxLength={1}
              onKeyDown={handleTokenDown}
              onChange={handleTokenDown}
              onPaste={handlePaste}
              inputMode="numeric"
              required
            />
          </div>
          <div className="w-12">
            <Input
              id="token2"
              name="token2"
              className="py-3 text-center"
              minLength={1}
              maxLength={1}
              onKeyDown={handleTokenDown}
              onChange={handleTokenDown}
              inputMode="numeric"
              required
            />
          </div>
          <div className="w-12">
            <Input
              id="token3"
              name="token3"
              className="py-3 text-center"
              minLength={1}
              maxLength={1}
              onKeyDown={handleTokenDown}
              onChange={handleTokenDown}
              inputMode="numeric"
              required
            />
          </div>
          <div className="w-12">
            <Input
              id="token4"
              name="token4"
              className="py-3 text-center"
              minLength={1}
              maxLength={1}
              onKeyDown={handleTokenDown}
              onChange={handleTokenDown}
              inputMode="numeric"
              required
            />
          </div>
          <div className="w-12">
            <Input
              id="token5"
              name="token5"
              className="py-3 text-center"
              minLength={1}
              maxLength={1}
              onKeyDown={handleTokenDown}
              onChange={handleTokenDown}
              inputMode="numeric"
              required
            />
          </div>
          <div className="w-12">
            <Input
              id="token6"
              name="token6"
              className="py-3 text-center"
              minLength={1}
              maxLength={1}
              onKeyDown={handleTokenDown}
              onChange={handleTokenDown}
              inputMode="numeric"
              required
            />
          </div>
        </div>
      </div>
      <div>
        {status === LoginState.InvalidLoginCode && (
          <p className="mt-6 rounded-md border border-red-200 bg-red-50 p-3 text-center text-sm text-red-600 dark:border-red-400 dark:bg-transparent dark:text-red-400">
            Invalid authentication token. Please try again.
          </p>
        )}
        <Button type="submit" className="mt-6" rightIcon={HiLockClosed} disabled={isLoading} variant="primary" block>
          {t('signIn')}
        </Button>
      </div>
    </div>
  )
}
